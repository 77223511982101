import React, { Fragment, useContext, useState } from "react";
import Cookies from "js-cookie";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  UncontrolledButtonDropdown,
  Button,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "./UserSetting";
import { ProfileContext } from "../contexts/ProfileContext";
import AddUserForm from "../PortalPages/Users/UserTable/AddUserForm";
import { checkIsAdmin } from "./GetUserAccess";

export default function UserBox() {
  const { logout, profile } = useContext(ProfileContext);
  const [modalShow, setModalShow] = useState(false);
  const [modalSetting, setModalSetting] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleLogout = () => {
    logout();
    Cookies.remove("authToken");
    Cookies.remove("userRoles");
    window.location.reload();
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <Fragment>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
              >
                <DropdownToggle
                  color="link"
                  className="p-0 d-flex align-items-center"
                >
                  <i className="metismenu-icon pe-7s-user text-secondary fs-1 " />
                  <FontAwesomeIcon
                    className="ms-2 opacity-8 text-secondary"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers dropdown-menu">
                  <Nav vertical>
                    <NavItem>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setModalShow(true);
                          closeDropdown();
                        }}
                        className={`user-btn text-secondary ${
                          checkIsAdmin() ? "" : "d-none"
                        }`}
                      >
                        Add User
                      </Button>
                      <AddUserForm
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </NavItem>
                    <NavItem>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setModalSetting(true);
                          closeDropdown();
                        }}
                        className="user-btn text-secondary"
                      >
                        Settings
                      </Button>
                      <UserSetting
                        show={modalSetting}
                        onHide={() => setModalSetting(false)}
                      />
                    </NavItem>
                    <NavItem>
                      <Button
                        className="user-btn text-danger"
                        onClick={() => {
                          handleLogout();
                          closeDropdown();
                        }}
                      >
                        Log Out
                      </Button>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ms-3 header-user-info">
              {profile ? (
                <div className="widget-heading">{profile.full_name}</div>
              ) : null}
              <div className="widget-subheading">
                {profile && profile.role ? profile.role : "Admin"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
