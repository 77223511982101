/* eslint-disable react/jsx-key */
import React from "react";
import { Modal } from "react-bootstrap";
import propTypes from "prop-types";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

const CitationModal = (props) => {
  CitationModal.propTypes = {
    citations: propTypes.any,
    show: propTypes.any,
    onHide: propTypes.func,
  };

  return (
    <Modal show={props.show} size="md" onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Citations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup as="ol" numbered>
          {props?.citations.flatMap((citation, i) => (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
              key={`${citation[0]?.metadata?.title}_${i}`}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{citation[0]?.metadata?.title}</div>
                <a
                  className="info-card-link"
                  href={citation[0]?.metadata?.source}
                  target="_blank"
                  rel="noreferrer"
                >
                  {citation[0]?.metadata?.source}
                </a>
              </div>
              <Badge bg="light" pill>
                {Number(citation[1]?.toFixed(4))}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
export default CitationModal;
