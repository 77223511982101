const routePaths = {
  dashboardTotal: "dashboards/total-data",
  dashboardFirebase: "dashboards/firebase-data",
  dashboardLocation: "dashboards/location-data",
  users: "user-management/users",
  userDetails: "user-management/user/",
  groups: "communities/groups",
  posts: "communities/posts",
  polls: "communities/polls",
  emailReport: "reports/email-stats",
  notificationReport: "reports/notification-stats",
  campaignReport: "reports/tracking-reports",
  adsManagement: "ads/ad-management",
  updateAdd: "ads/ad",
  adsReport: "ads/ads-stats",
  adsLeads: "ads/ads-leads",
  campaign: "tracking/campaigns",
  feed: "feed/feed",
  products: "product-management/products",
  communicationSystem: "email/bulk-email",
  sponsoredPosts: "brandlabs/sponsored-posts",
  sponsoredPostDetails: "brandlabs/sponsoredpost-detail",
  emailAds: "brandlabs/email-ads",
  coupons: "service-management/services",
  services: "tracking/campaigns",
  groupDetails: "communities/group",
  postDetails: "communities/post",
  productDetails: "product-management/product",
  serviceDetails: "service-management/service",
  buzzFeed: "buzz-feed",
  installReports: "reports/install-stats",
  dashboardInstallation: "dashboards/installation-data",
  config: "app-setting",
  videos: "videos",
  connections: "reports/connection-stats",
  activity: "reports/activity-stats",
  groupsReport: "reports/groups",
  silentNotification: "reports/silent-notification",
  scrollReports: "reports/scroll-stats",
  searchHistory: "reports/search-history",
  brands: "brandlabs",
  brandLabsCampaigns: "brandlabs/campaigns-list",
  content: "content",
  stories: "stories",
  toDoList: "to-do-list",
  tool: "tools",
  feedback: "feedback",
  chat: "chat",
};
const adminRoutes = [
  routePaths.dashboardTotal,
  routePaths.dashboardFirebase,
  routePaths.dashboardLocation,
  routePaths.users,
  routePaths.userDetails,
  routePaths.groups,
  routePaths.posts,
  routePaths.polls,
  routePaths.emailReport,
  routePaths.notificationReport,
  routePaths.adsManagement,
  routePaths.adsReport,
  routePaths.adsLeads,
  routePaths.campaign,
  routePaths.products,
  routePaths.communicationSystem,
  routePaths.sponsoredPosts,
  routePaths.sponsoredPostDetails,
  routePaths.emailAds,
  routePaths.coupons,
  routePaths.services,
  routePaths.groupDetails,
  routePaths.postDetails,
  routePaths.campaignReport,
  routePaths.feed,
  routePaths.productDetails,
  routePaths.serviceDetails,
  routePaths.updateAdd,
  routePaths.buzzFeed,
  routePaths.installReports,
  routePaths.dashboardInstallation,
  routePaths.config,
  routePaths.videos,
  routePaths.connections,
  routePaths.activity,
  routePaths.scrollReports,
  routePaths.brands,
  routePaths.searchHistory,
  routePaths.brandLabsCampaigns,
  routePaths.content,
  routePaths.groupsReport,
  routePaths.silentNotification,
  routePaths.stories,
  routePaths.toDoList,
  routePaths.tool,
  routePaths.feedback,
  routePaths.chat,
];
const adManagerRoutes = [
  routePaths.dashboardTotal,
  routePaths.dashboardFirebase,
  routePaths.emailReport,
  routePaths.notificationReport,
  routePaths.emailAds,
];

const internalUserRoutes = [routePaths.users, routePaths.userDetails];

const contentManagerRoutes = [
  routePaths.content,
  routePaths.buzzFeed,
  routePaths.feed,
  routePaths.videos,
  routePaths.products,
  routePaths.coupons,
  routePaths.stories,
  routePaths.toDoList,
  routePaths.tool,
];

const routes = {
  adminRoutes,
  adManagerRoutes,
  internalUserRoutes,
  contentManagerRoutes,
};
export default routes;
