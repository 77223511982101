import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import Chat from "./Chat";
import propTypes from "prop-types";

const Chats = ({ match }) => {
  Chats.propTypes = {
    match: propTypes.any,
  };
  return (
    <Fragment>
      <Route path={`${match.url}`} component={Chat} />
    </Fragment>
  );
};

export default Chats;
