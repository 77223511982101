import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "./ErrorHandling";

const ChatAPI = {
  createChat: async (data) => {
    const method = "post";

    try {
      const response = await BTPAuth.fetchData(
        apiUrl.chat + "api/ai/v1/chat",
        method,
        {},
        data,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },
};

export default ChatAPI;
