import routes from "./RoutePaths";

const submenuItems = {
  dashboardTotal: {
    label: "User Analytics",
    to: "#/dashboards/total-data",
  },
  dashboardFirebase: {
    label: "Firebase Analytics",
    to: "#/dashboards/firebase-data",
  },
  dashboardLocation: {
    label: "Location",
    to: "#/dashboards/location-data",
  },
  dashboardInstallation: {
    label: "Installations",
    to: "#/dashboards/installation-data",
  },
  users: {
    label: "Users",
    to: "#/user-management/users",
    className: "text-secondary",
  },
  usersRegistration: {
    label: "Registrations",
    to: "#/user-management/users-registration",
    className: "text-secondary",
  },
  groups: {
    label: "Groups",
    to: "#/communities/groups",
  },
  posts: {
    label: "Posts",
    to: "#/communities/posts",
  },
  polls: {
    label: "Polls",
    to: "#/communities/polls",
  },
  /*
   * Announcements: {
   *   label: 'Announcements',
   *   to: '#/dashboards/users',
   * },
   * connections: {
   *   label: 'Connections',
   *   to: '#/dashboards/users',
   * },
   * chat: {
   *   label: 'Chat',
   *   to: '#/dashboards/users',
   * }
   */
  emailReport: {
    label: "Email Report",
    to: "#/reports/email-stats",
  },
  notificationReport: {
    label: "Notification Report",
    to: "#/reports/notification-stats",
  },
  campaignReport: {
    label: "Tracking Report",
    to: "#/reports/tracking-reports",
  },
  connectionReport: {
    label: "Connection Report",
    to: "#/reports/connection-stats",
  },
  activityReport: {
    label: "Activity Report",
    to: "#/reports/activity-stats",
  },
  scrollReport: {
    label: "Scroll Report",
    to: "#/reports/scroll-stats",
  },
  searchHistory: {
    label: "Search History",
    to: "#/reports/search-history",
  },
  adsManagement: {
    label: "Ads Management",
    to: "#/ads/ad-management",
  },
  adsReport: {
    label: "Ads Report",
    to: "#/ads/ads-stats",
  },
  adsLeads: {
    label: "Ads Leads",
    to: "#/ads/ads-leads",
  },
  installReports: {
    label: "Installation Report",
    to: "#/reports/install-stats",
  },
  campaign: {
    icon: "pe-7s-link",
    label: "Campaign",
    to: "#/tracking/campaigns",
  },
  groupsReport: {
    label: "Groups Report",
    to: "#/reports/groups",
  },
  silentNotification: {
    label: "Uninstall Report",
    to: "#/reports/silent-notification",
  },
  content: {
    label: "Content",
    to: "#/content/week-list",
  },
  stories: {
    label: "Stories",
    to: "#/stories/stories-list",
  },
  products: {
    label: "Products",
    to: "#/product-management/products",
  },

  videos: {
    label: "Videos",
    to: "#/videos",
  },
  toDoList: {
    label: "To Do List",
    to: "#/to-do-list/list",
  },

  services: {
    label: "Services",
    to: "#/service-management/services",
  },

  buzzFeed: {
    label: "Buzz Feed",
    to: "#/buzz-feed",
  },

  feed: {
    label: "Feed",
    to: "#/feed/feed",
  },

  tool: {
    label: "Tools",
    to: "#/tools/tools-list",
  },

  appSetting: {
    label: "App Config",
    to: "#/app-setting",
  },
};

const menuItems = {
  btp: {
    name: "BTP",
    type: "heading",
  },
  dashboard: {
    name: "Dashboard",
    type: "heading",
  },
  brandLabs: {
    name: "BrandLabs",
    type: "heading",
  },
  dashboardMenu: {
    type: "mainMenu",
    icon: "pe-7s-display2",
    label: "Dashboard",
  },
  userManagement: {
    type: "mainMenu",
    icon: "pe-7s-users",
    label: "User Management",
  },

  communicationSystem: {
    type: "mainMenu",
    label: "Communication System",
    icon: "pe-7s-mail-open-file",
    to: "#/email/bulk-email",
  },
  reports: {
    type: "mainMenu",
    icon: "pe-7s-file",
    label: "Reports",
  },
  communities: {
    type: "mainMenu",
    icon: "pe-7s-comment",
    label: "Communities",
  },
  ads: {
    type: "mainMenu",
    icon: "pe-7s-display1",
    label: "Ads",
    to: "#/ads/ad-management",
  },
  brands: {
    icon: "pe-7s-display1",
    label: "Brands",
    to: "#/brandlabs/brands",
  },
  campaigns: {
    icon: "pe-7s-display1",
    label: "Campaigns",
    to: "#/brandlabs/campaigns-list",
  },
  sponsoredPosts: {
    icon: "pe-7s-display1",
    label: "Sponsored Posts",
    to: "#/brandlabs/sponsored-posts",
  },
  emalAds: {
    icon: "pe-7s-paper-plane",
    label: "Email Ads",
    to: "#/brandlabs/email-ads",
  },
  coupons: {
    icon: "pe-7s-paper-plane",
    label: "Coupons",
    to: "#/brandlabs/coupon-add",
  },

  tracking: {
    type: "mainMenu",
    icon: "pe-7s-link",
    label: "Tracking",
  },
  Config: {
    type: "mainMenu",
    icon: "pe-7s-link",
    label: "Config",
  },

  Feedback: {
    type: "mainMenu",
    icon: "pe-7s-display1",
    label: "Feedback",
    to: "#/feedback",
  },

  Chat: {
    type: "mainMenu",
    icon: "pe-7s-chat",
    label: "Chat",
    to: "#/chat",
  },

  content: {
    type: "mainMenu",
    icon: "pe-7s-phone",
    label: "Content",
  },
};

const commonMenu = {
  item: menuItems.dashboard,
  mainMenu: [
    {
      ...menuItems.dashboardMenu,
      content: [
        submenuItems.dashboardTotal,
        submenuItems.dashboardFirebase,
        submenuItems.dashboardLocation,
        submenuItems.dashboardInstallation,
      ],
    },
  ],
};

const adminMenu = [
  commonMenu,
  {
    item: menuItems.btp,
    routes: routes.adminRoutes,
    mainMenu: [
      {
        ...menuItems.userManagement,
        content: [submenuItems.users, submenuItems.usersRegistration],
      },
      {
        ...menuItems.communicationSystem,
      },
      {
        ...menuItems.communities,
        content: [submenuItems.groups, submenuItems.posts, submenuItems.polls],
      },
      {
        ...menuItems.ads,
      },
      {
        ...menuItems.content,
        content: [
          submenuItems.content,
          submenuItems.buzzFeed,
          submenuItems.feed,
          submenuItems.videos,
          submenuItems.products,
          submenuItems.services,
          submenuItems.stories,
          submenuItems.toDoList,
          submenuItems.tool,
        ],
      },
      {
        ...menuItems.reports,
        content: [
          submenuItems.emailReport,
          submenuItems.notificationReport,
          submenuItems.campaignReport,
          /*
           * {
           *   submenuItems.installReports,
           * }
           */
          submenuItems.connectionReport,
          submenuItems.activityReport,
          submenuItems.scrollReport,
          submenuItems.silentNotification,
          submenuItems.groupsReport,
          submenuItems.searchHistory,
        ],
      },
      {
        ...menuItems.tracking,
        content: [submenuItems.campaign],
      },

      {
        ...menuItems.Config,
        content: [submenuItems.appSetting],
      },
      {
        ...menuItems.Feedback,
      },
      // {
      //   ...menuItems.Chat,
      // },
    ],
  },
  {
    item: menuItems.brandLabs,
    mainMenu: [
      {
        ...menuItems.sponsoredPosts,
      },

      {
        ...menuItems.emalAds,
      },
      {
        ...menuItems.coupons,
      },

      {
        ...menuItems.brands,
      },
      {
        ...menuItems.campaigns,
      },
    ],
  },
];

const internalUserMenu = [
  {
    item: menuItems.btp,
    routes: routes.internalUserRoutes,
    mainMenu: [
      {
        ...menuItems.userManagement,
        content: [submenuItems.users],
      },
      /*
       * {
       *   ...menuItems.communities, content: [submenuItems.groups, submenuItems.posts]
       * }
       */
    ],
  },
];

const contentManagerMenu = [
  {
    item: menuItems.btp,
    routes: routes.contentManagerRoutes,
    mainMenu: [
      {
        ...menuItems.content,
        content: [
          submenuItems.content,
          submenuItems.buzzFeed,
          submenuItems.feed,
          submenuItems.videos,
          submenuItems.products,
          submenuItems.services,
          submenuItems.stories,
          submenuItems.toDoList,
          submenuItems.tool,
        ],
      },
    ],
  },
];

const adManagerMenu = [
  commonMenu,
  {
    item: menuItems.btp,
    routes: routes.adManagerRoutes,
    mainMenu: [
      {
        ...menuItems.reports,
        content: [submenuItems.emailReport, submenuItems.notificationReport],
      },
      {
        ...menuItems.ads,
      },
      {
        ...menuItems.mainmenu,
      },
    ],
  },
];

export const BTP = {
  adminMenu,
  adManagerMenu,
  internalUserMenu,
  contentManagerMenu,
};
