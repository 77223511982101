import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Form, Container } from "react-bootstrap";
import Login from "../../services/Login";
import Cookies from "js-cookie";
import { ProfileContext } from "../../contexts/ProfileContext";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

export default function LoginPage() {
  const { login } = useContext(ProfileContext);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [openForgetModal, setOpenForgetModal] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useHistory();

  const sentPhoneOtp = async () => {
    const data = await Login.requestPhoneOtp({
      phone,
    });
    if (data?.status === 200) {
      setIsOtpSent(true);
    }
  };
  const verifyOtp = async () => {
    const data = await Login.verifyPhoneOtp({
      phone,
      otp,
    });
    if (data?.status === 200) {
      const path = Cookies.get("path");
      const accessToken = Cookies.get("authToken");
      await login(accessToken);
      if (path !== "") {
        navigate.push(path);
      } else setError("You are not authorized");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isOtpSent === true) {
      await verifyOtp();
    } else {
      await sentPhoneOtp();
    }
  };
  return (
    <>
      {openForgetModal && (
        <ForgotPassword
          openForgetModal={openForgetModal}
          setOpenForgetModal={setOpenForgetModal}
        />
      )}
      <Container className="d-flex align-items-center justify-content-center h-100">
        <Card id="login-card" className="p-5">
          <img
            height="auto"
            alt=""
            src={process.env.PUBLIC_URL + "/images/being-the-parent.webp"}
          />
          <Form className="mt-5">
            <Form.Group>
              <Form.Label for="exampleEmail">Phone Number</Form.Label>
              <Form.Control
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                id="examplePhone"
                placeholder="Enter your phone number"
              />
            </Form.Group>
            {isOtpSent === true ? (
              <Form.Group className="mt-5">
                <Form.Label for="otp">Enter OTP</Form.Label>
                <Form.Control
                  type="number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  name="otp"
                  id="otp"
                  placeholder="Enter OTP"
                />
              </Form.Group>
            ) : null}
            {error && <p className="validation-error mt-2 mb-0">{error}</p>}
            <Button
              type="submit"
              className="btn login-btn mt-5 w-100"
              onClick={handleSubmit}
              disabled={
                phone === ""
                  ? isOtpSent === true && otp === ""
                    ? true
                    : false
                  : false
              }
            >
              Submit
            </Button>
          </Form>

          {/* <button
            className="forget-pass mt-2"
            onClick={handleClikForgetPassword}
          >
            Forgot Password
          </button> */}

          {/* <h6 className="slashed-text">
            <em>OR</em>
          </h6> */}
          {/* <GoogleOAuthProvider clientId="285015230290-8hf0spbdpceq31bjbgc80bpg3e97i1qd.apps.googleusercontent.com">
            <GoogleSignInBtn />
          </GoogleOAuthProvider> */}
        </Card>
      </Container>
    </>
  );
}
