import React, { useState } from "react";
import "./Chat.css";
import { Button, Form, InputGroup } from "react-bootstrap";
import ChatAPI from "../../services/Chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import CitationModal from "./CitationModal";
function Chat() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [citationModal, setCitationModal] = useState(false);
  const [citations, setCitations] = useState([]);
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
  const sendChat = async () => {
    const data = {
      chatHistory,
      question: inputText,
      messages,
    };
    try {
      const aiResponse = await ChatAPI.createChat(data);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: aiResponse?.data?.data?.result,
          sender: "maya",
          sourceDocuments: aiResponse?.data?.data?.sourceDocuments || [],
        },
      ]);
    } catch (error) {
      console.error("Error sending chat:", error);
    }
    setIsButtonDisabled(false);
  };

  const handleSendMessage = async () => {
    setIsButtonDisabled(true);
    if (inputText.trim() !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputText, sender: "user" },
      ]);
      setChatHistory([...chatHistory, inputText]);
      setInputText("");
      await sendChat();
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      setIsButtonDisabled(true);
      if (inputText.trim() !== "") {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: inputText, sender: "user" },
        ]);
        setChatHistory([...chatHistory, inputText]);
        setInputText("");
        await sendChat();
      }
    }
  };

  const openCitationModal = (event, citations) => {
    event.preventDefault();
    setCitationModal(true);
    setCitations(citations);
  };
  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.sender === "user" ? (
              <>
                <FontAwesomeIcon
                  variant="outline-secondary"
                  icon={faUser}
                  className="fs-6 p-0 m-0"
                ></FontAwesomeIcon>{" "}
                {message.text}
              </>
            ) : (
              <>
                <img
                  className="maya-image"
                  src={process.env.PUBLIC_URL + "/images/mama-chatbot.svg"}
                  alt="image"
                />{" "}
                {message.text}
                <a
                  onClick={(event) =>
                    openCitationModal(event, message?.sourceDocuments)
                  }
                  className="red-text"
                >
                  Citation
                </a>
              </>
            )}
          </div>
        ))}
      </div>
      <Form>
        <div className="input-container">
          <InputGroup className="mb-3">
            <Form.Control
              className="form-control"
              onChange={handleInputChange}
              type="text"
              value={inputText}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="btn btn-outline-primary"
              disabled={isButtonDisabled ? true : false}
              onClick={handleSendMessage}
            >
              <FontAwesomeIcon
                variant="outline-secondary"
                icon={faArrowUp}
                className="fs-6 p-0 m-0"
              ></FontAwesomeIcon>
            </Button>
          </InputGroup>
        </div>
      </Form>
      <CitationModal
        show={citationModal}
        onHide={() => setCitationModal(!citationModal)}
        citations={citations}
      />
    </div>
  );
}

export default Chat;
